import { colors } from '@authentic/loyalty-pwa/design-system/style-guide/color';
import { Breakpoints } from '@greencross/utils/breakpoints';
import styled from 'styled-components';

export const NavSliderStyled = styled.div`
  .swiper-button-prev {
    width: 40px;
    border-top-right-radius: 45%;
    background-color: #fff;
    border-bottom-right-radius: 45%;
    padding: 24px 20px 24px 10px;
    top: 46.5%;
    left: 0;
    @media screen and (max-width: ${Breakpoints.L}) {
      padding: 18px 25px 18px 13px;
      top: 48.5%;
      border-top-right-radius: 100%;
      border-bottom-right-radius: 100%;
      width: 38px;
    }
    @media screen and (max-width: ${Breakpoints.XS}) {
      padding: 9px 6px 9px 0px;
      height: 20px;
      width: 17px;
      top: 50%;
      margin-top: -14px;
    }
  }
  .swiper-slide {
    max-height: 430px;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 0;
    left: auto;
    width: 40px;
    border-top-left-radius: 45%;
    background-color: #fff;
    border-bottom-left-radius: 45%;
    padding: 24px 10px 24px 20px;
    top: 46.5%;
    @media screen and (max-width: ${Breakpoints.L}) {
      padding: 18px 13px 18px 25px;
      top: 48.5%;
      border-top-left-radius: 100%;
      border-bottom-left-radius: 100%;
      width: 38px;
    }
    @media screen and (max-width: ${Breakpoints.XS}) {
      padding: 9px 0px 9px 6px;
      height: 20px;
      width: 17px;
      top: 50%;
      margin-top: -14px;
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: ${colors.orange};
    font-size: 2rem;
    @media screen and (max-width: ${Breakpoints.XS}) {
      font-size: 1rem;
    }
  }
  .swiper-pagination {
    width: 200px;
    left: 50%;
    margin-left: -100px;
  }
}

  .swiper-pagination-bullet {
    background: #fff;
    opacity: 0.5;
    width: 20px;
    height: 20px;
    @media screen and (max-width: ${Breakpoints.L}) {
      width: 15px;
      height: 15px;
    }
    @media screen and (max-width: ${Breakpoints.XS}) {
      width: 8px;
      height: 8px;
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 20px;
    @media screen and (max-width: ${Breakpoints.XS}) {
      bottom: 8px;
    }
  }
`;
